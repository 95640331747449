body {
  height: 100vh;
  /*background: #f7f7f7; */
  margin: 0;
  /*background-image: url('${result.result[0].banner_path}');          */
  background-position: center -25px;
  background-repeat: no-repeat;
  background-size: 100% 600px;
  color: #000;
  font-family: "Poppins", system-ui, sans-serif !important;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  color: #171617 !important;
  font-weight: 500 !important;
}

h3 {
  font-size: 1.2rem !important;
}

.contenedor-loader {
  min-height: 270px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.contenedor-btn .btn-check {
  width: 50px;
  height: 50px;
  font-size: 20px;
  line-height: 20px;
  background: #2fb74b;
  color: #fff;
  position: relative;
  top: 7px;
  z-index: 3;
  border-radius: 100%;
  /*box-shadow: -5px 4px 8px rgba(236, 164, 87, 0.37);*/
  padding: 5px;
  transition: all 0.2s ease;
  border: none;
  cursor: pointer;
}

#qr_image {
  width: 100%;
  max-width: 333px;
  margin-bottom: 0px;
  border-radius: 1px;
  box-shadow: 0 10px 10px 0 rgba(90, 116, 148, 0.3);
}
.title {
  text-align: center;
  background: #f78956;
  margin: 0px;
  padding: 19px 0;
  color: #fff !important;
  font-weight: 700;
  min-height: 76px;
  text-align: center;
  display: flex;
  justify-content: center;
}
h1 {
  font-size: 1.75rem !important;
}
h3 {
  margin: 0.5rem;
}
.mensaje {
  padding: 4px;
  display: flex;
  justify-content: center;
  font-size: 13px;
}

.mensaje div {
  width: 330px;
  min-height: 16px;
}

.mensaje span {
  /*  font-style: italic;*/
  font-size: 14px;
}

.grupo .label {
  font-size: 16px;
  color: #7d8994;
  margin-bottom: 5px;
  line-height: 1.5rem;
}

.title div {
  margin-left: 5px;
}

.image-logo {
  width: 100%;
  max-width: 250px;
  max-height: 100%;
  object-fit: cover;
}
.pay-logo {
  width: 85px;
  height: 45px;
}
/*mobile*/
@media screen and (max-width: 768px) {
  body {
    background: #fff;
  }

  .invoice-form {
    width: 90% !important;
  }

  h1 {
    font-size: 1.5rem !important;
  }
  h3 {
    font-size: 1.125rem !important;
  }
  .image-logo {
    width: 40%;
    max-width: 40%;
  }
  .pay-logo {
    width: 75px;
    height: 37px;
  }
  .contenedor {
    height: 280px !important;
  }
  .delantera .logo-marca {
    min-height: 10px !important;
  }

  .delantera .grupo .label {
    font-size: 14px !important;
  }

  .delantera .logo-marca img {
    width: 80% !important;
    height: 80% !important;
    max-width: 75px !important;
  }

  .delantera .chip {
    max-width: 45px !important;
  }
  .tarjeta .delantera {
    padding: 12px !important;
    min-height: 250px !important;
    justify-content: space-between !important;
  }

  .tarjeta .trasera {
    padding: 12px !important;
    min-height: 250px !important;
    justify-content: flex-start !important;
  }

  .tarjeta .datos {
    height: 126px !important;
    margin-top: 10px !important;
  }

  .delantera .grupo .numero,
  .delantera .grupo .nombre,
  .delantera .grupo .expiracion {
    line-height: 1.125rem !important;
    font-size: 15px !important;
  }

  p {
    margin-bottom: 0rem !important;
  }

  .trasera .datos #firma {
    width: 100% !important;
    height: 90px;
    margin-top: 50px;
  }

  .trasera .datos #cvv {
    height: 90px;
    margin-top: 50px;
  }

  .trasera .leyenda {
    line-height: 18px !important;
    margin-top: 35px;
  }

  .trasera .datos #firma .firma p {
    line-height: 36px !important;
    font-size: 21px !important;
  }

  .delantera .flexbox {
    margin-top: 15px !important;
  }

  .title {
    font-size: 1.35rem !important;
    display: grid !important;
  }

  .title div {
    margin-left: 0 !important;
  }

  .c-h1 {
    margin-top: 20px;
  }
  .mensaje {
    padding: 1px 17px 0 17px !important;
  }
  #qr_image {
    width: 90%;
    max-width: 375px;
  }
  h2 {
    margin: 6px 12px 2px 12px;
  }
  .ccontainer {
    margin-top: 1px !important;
  }
  .ccards {
    margin: 0px 5px !important;
  }
  .ccard {
    padding: 0 20px !important;
  }

  .ccontainer {
    padding-top: 65px;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 10px;
    height: 100vh !important;
    /*background: #fff !important;*/
  }
}
.ccontainer {
  padding-top: 65px;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 10px;
  height: 499px;
  background: rgb(0, 59, 113);
}
.ccards {
  display: flex; /* Usa Flexbox */
  flex-wrap: wrap; /* Permite que los elementos se envuelvan a una nueva línea si no caben en el ancho */
  max-width: 1200px; /* Limita el ancho del contenedor */
  background: #f4f4f4;
  border-radius: 16px;
  border: 1px solid #ccc;
}
.ccard {
  flex: 1 0 50%; /* Hace que los elementos se expandan para llenar el espacio disponible */
  padding: 20px;

  box-sizing: border-box; /* Incluye el padding y border en el ancho y alto del elemento */
}
.ccard:first-child {
  border-bottom: 1px solid #ccc; /* Agrega un borde derecho al primer .ccard */
}
.contenedor-btn .btn-trash {
  width: 50px;
  height: 50px;
  font-size: 20px;
  line-height: 20px;
  background: red;
  color: #fff;
  position: relative;
  top: 7px;
  z-index: 3;
  border-radius: 100%;
  /*box-shadow: -5px 4px 8px rgba(236, 164, 87, 0.37);*/
  padding: 5px;
  transition: all 0.2s ease;
  border: none;
  cursor: pointer;
}

.btn-enviar {
  border: 2px solid #ced6e0;
  font-size: 16px;

  transition: 0.3s ease all;
  border-radius: 5px;
}

#formulario {
  background: #fff;
  width: 100%;
  max-width: 700px;
  padding: 10px 0px 30px 0px;
  border-radius: 10px;
}

#formulario .btn-enviar {
  border: none;
  padding: 10px 20px;
  font-size: 22px;
  color: #fff;
  background: #ff5100;
  /*box-shadow: -5px 4px 8px rgba(236, 164, 87, 0.37);*/
  cursor: pointer;
}

#formulario .btn-enviar:hover {
  background: #003b71;
}

#formulario label {
  display: block;
  color: #7d8994;
  margin-bottom: 5px;
  font-size: 16px;
}

#formulario input {
  margin-bottom: 10px;
  text-transform: uppercase;
}
#formulario input {
  height: 36px;
  padding: 0px 0 0 5px;
  width: 150px;
  border-radius: 4px;
  border: 1px solid #959595;
  font-size: 16px;
}
#formulario .btn-enviar {
  border: 2px solid #ced6e0;
  font-size: 16px;
  transition: 0.3s ease all;
  border-radius: 5px;
}

.ccards {
  display: flex; /* Usa Flexbox */
  flex-wrap: wrap; /* Permite que los elementos se envuelvan a una nueva línea si no caben en el ancho */
  max-width: 1200px; /* Limita el ancho del contenedor */
  background: #f6f5f5;
  border-radius: 16px;
  border: 1px solid #ccc;
}

.ccard {
  flex: 1 0 50%; /* Hace que los elementos se expandan para llenar el espacio disponible */
  padding: 20px;

  box-sizing: border-box; /* Incluye el padding y border en el ancho y alto del elemento */
}
/*
.ccard:first-child {
  border-bottom: 1px solid #ccc; 
}

.ccard:last-child {
  border-top: 1px solid #ccc; 
}*/

.image-card {
  flex-basis: 100%; /* Ocupa todo el ancho disponible en una nueva línea */
  padding: 20px;
  box-sizing: border-box;
}
.border-top {
  border-top: 1px solid #ccc;
}
.header-card {
  flex-basis: 100%; /* Ocupa todo el ancho disponible en una nueva línea */
  padding: 0px;
  box-sizing: border-box;
  flex-basis: 100%;
  padding: 0px;
  box-sizing: border-box;
  min-height: 81px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.contenedor {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 375px;
  transition: clip-path 0.3s ease-out;
}

.contenedor.active {
  height: 752px;
  transition: clip-path 0.3s ease-out;
}

/* ---------- Estilos Generales de las Tarjetas ----------*/
.tarjeta {
  width: 100%;
  position: relative;
  color: #fff;
  transition: 0.3s ease all;
  transform: rotateY(0deg);
  transform-style: preserve-3d;
  cursor: pointer;
  z-index: 2;
}

.tarjeta.active {
  transform: rotateY(180deg);
}

.tarjeta > div {
  padding: 12px 18px;
  border-radius: 16px;
  min-height: 309px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  box-shadow: 0 10px 10px 0 rgba(90, 116, 148, 0.3);
}

/* ---------- Tarjeta Delantera ----------*/

.tarjeta .delantera {
  /*width: 100%;*/
  background: #ff5100; /* #171617;*/
  background-size: cover;
}

.delantera .logo-marca {
  text-align: right;
  min-height: 50px;
}

.delantera .logo-marca img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  max-width: 80px;
}

.delantera .chip {
  width: 100%;
  max-width: 50px;
  margin-bottom: 0px;
}

.grupo {
  width: 96%;
  padding-left: 2%;
}

.delantera .grupo .label {
  font-size: 16px;
  color: #fff; /*#7d8994;*/
  margin-bottom: 5px;
  line-height: 1.5rem;
}

.delantera .grupo .numero,
.delantera .grupo .nombre,
.delantera .grupo .expiracion {
  color: #fff;
  font-size: 22px;
  text-transform: uppercase;
  line-height: 1.5rem;
}

.delantera .flexbox {
  display: flex;
  justify-content: space-between;
  margin-top: 45px;
}

/* ---------- Tarjeta Trasera ----------*/
.trasera {
  background: #35526c; /* #003b71;*/
  background-size: cover;
  position: absolute;
  top: 0;
  transform: rotateY(180deg);
  backface-visibility: hidden;
}

.trasera .barra-magnetica {
  height: 40px;
  background: #000;
  width: 100%;
  position: absolute;
  top: 30px;
  left: 0;
}
.tarjeta .datos {
  height: 196px;
  margin-top: 20px;
}
.trasera .datos {
  margin-top: 60px;
  display: flex;
  justify-content: space-between;
}

.trasera .datos p {
  margin-bottom: 5px;
}

.trasera .datos #firma {
  width: 70%;
}

.trasera .datos #firma .firma {
  height: 40px;
  background: repeating-linear-gradient(
    skyblue 0,
    skyblue 5px,
    orange 5px,
    orange 10px
  );
}

.trasera .datos #firma .firma p {
  line-height: 40px;
  font-family: "Liu Jian Mao Cao", cursive;
  color: #000;
  font-size: 30px;
  padding: 0 10px;
  text-transform: capitalize;
}

.trasera .datos #cvv {
  width: 20%;
}

.trasera .datos #cvv .cvv {
  background: #fff;
  height: 40px;
  color: #000;
  padding: 10px;
  text-align: center;
}

.trasera .leyenda {
  font-size: 14px;
  line-height: 24px;
}

.trasera .link-banco {
  font-size: 14px;
  color: #fff;
}

/* ---------- Contenedor Boton ----------*/
.contenedor-btn .btn-abrir-formulario {
  width: 50px;
  height: 50px;
  font-size: 20px;
  line-height: 20px;
  background: #003b71; /* #ff5100;*/
  color: #fff;
  position: relative;
  top: -25px;
  z-index: 3;
  border-radius: 100%;
  /*box-shadow: -5px 4px 8px rgba(236, 164, 87, 0.37);*/
  padding: 5px;
  transition: all 0.2s ease;
  border: none;
  cursor: pointer;
}

.contenedor-btn .btn-abrir-formulario:hover {
  background: #003b71;
}

.contenedor-btn .btn-abrir-formulario.active {
  transform: rotate(45deg);
}

/* ---------- Formulario Tarjeta ----------*/
.formulario-tarjeta {
  background: #fff;
  width: 100%;
  max-width: 700px;
  padding: 112px 0px 30px 0px;
  border-radius: 10px;
  position: relative;
  top: -153px;
  z-index: 1;
  clip-path: polygon(0 0, 100% 0, 100% 0, 0 0);
  transition: clip-path 0.3s ease-out;
}

.formulario-tarjeta.active {
  clip-path: polygon(0 0, 100% 0, 100% 100%, 0 100%);
}

.formulario-tarjeta label {
  display: block;
  color: #7d8994;
  margin-bottom: 5px;
  font-size: 16px;
}

.formulario-tarjeta input,
.formulario-tarjeta select,
.btn-enviar {
  border: 2px solid #ced6e0;
  font-size: 16px;

  transition: 0.3s ease all;
  border-radius: 5px;
}

.formulario-tarjeta input {
  height: 36px;
  padding: 0px 0 0 5px;
  width: 99%;
}

.formulario-tarjeta select {
  height: 41px;
  padding: 5px 5px;
  width: 100%;
}

.formulario-tarjeta input:hover,
.formulario-tarjeta select:hover {
  border: 2px solid #93bded;
}

.formulario-tarjeta input:focus,
.formulario-tarjeta select:focus {
  outline: rgb(4, 4, 4);
  box-shadow: 1px 7px 10px -5px rgba(90, 116, 148, 0.3);
}

.formulario-tarjeta input {
  margin-bottom: 10px;
  text-transform: uppercase;
}

.formulario-tarjeta .flexbox {
  display: flex;
  justify-content: space-between;
}

.formulario-tarjeta .expira {
  width: 100%;
}

.formulario-tarjeta .cvv {
  min-width: 100px;
}

.formulario-tarjeta .grupo-select {
  width: 100%;
  margin-right: 15px;
  position: relative;
}

.formulario-tarjeta select {
  -webkit-appearance: none;
}

.formulario-tarjeta .grupo-select i {
  position: absolute;
  color: #ced6e0;
  top: 18px;
  right: 15px;
  transition: 0.3s ease all;
}

.formulario-tarjeta .grupo-select:hover i {
  color: #93bfed;
}

.formulario-tarjeta .btn-enviar {
  border: none;
  padding: 10px;
  font-size: 22px;
  color: #fff;
  background: #ff5100;
  /*box-shadow: -5px 4px 8px rgba(236, 164, 87, 0.37);*/
  cursor: pointer;
  width: 100%;
}

.formulario-tarjeta .btn-enviar:hover {
  background: #003b71;
}

.loadingBG {
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1090;
  position: fixed;
  top: 0;
  left: 0;
  opacity: 0.5;
  width: 100vw;
  height: 100vh;
  background-color: #fff;
  transition: opacity 0.15s linear;
}

.loadingBG2 {
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1090;
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
}

.descarga a,
a:visited,
a.active,
a.hover {
  color: #1e1e1e;
  text-decoration: underline;
  font-size: 14px;
  font-weight: 600;
}

.invoice-form {
  width: 97%;
  padding: 10px 10px 5px 10px;
  border: 1px solid #ccc;
  border-radius: 16px;
  margin-top: 14px;
  color: #313131;
  background: #fff;
}

.invoice-form label {
  font-weight: 500;
  font-size: 1rem;
}

.invoice-form input,
.invoice-form select,
.invoice-form button {
  width: 100%;
  border-radius: 5px;
  margin-bottom: 5px;
  height: 36px;
  padding: 0px 0 0 5px;
  border: 2px solid #ced6e0;
}

.invoice-form input:hover,
.invoice-form select:hover {
  border: 2px solid #93bded;
}

#invoice-form {
  overflow: hidden;
  transition: max-height 0.5s ease-out;
  max-height: 500px; /* Set a default max-height larger than the form */
}

.collapsed {
  max-height: 0 !important;
}

.toggle-button {
  transform: rotate(90deg);
}
.text-right {
  text-align: right;
}

.plus {
  cursor: pointer;
  font-size: 25px;
  border-radius: 25%;
  border: 1px solid #777;
  padding: 0px 5px;
  color: #777;
  width: 22px;
  display: block;
  height: 20px;
  line-height: 1px;
  width: 20px;
  padding-top: 9px;
  padding-left: 2px;
}
.minus {
  cursor: pointer;
  font-size: 25px;
  border-radius: 25%;
  border: 1px solid #777;
  padding: 0px 5px;
  color: #777;
  width: 22px;
  display: block;
  height: 20px;
  line-height: 1px;
  width: 20px;
  padding-top: 7px;
  padding-right: 4px;
}
.flex-right {
  display: flex;
  justify-content: right;
}

.invoice-button {
  padding: 0px !important;
  font-size: 20px;
  color: #fff;
  background: #ff5100;
  box-shadow: -5px 4px 8px rgba(236, 164, 87, 0.37);
  cursor: pointer;
  height: 45px !important;
}
.invoice-title {
  color: #1b1b1b;
  font-weight: 700;
  font-size: 1.125rem;
}
.invoice-subtitle {
  height: 25px;
  line-height: 20px;
  padding-right: 4px;
  color: #1b1b1b;
  position: relative;
  right: 0;
  width: 100%;
  text-decoration: underline;
}
